import React, { useState,useEffect } from "react";
import SideBar from "./SideBar";

class NewModal extends React.Component {
   divStyle={
  
    height:'500px',

  };
  constructor(props) {
   
    super(props);
    this.state = {
        showModal:false,
        currencyList:[],
        addedCurrency:[],


   
  
  
    };
   
    this.changeStatus = props.changeStatus.bind(this);

    
  }

 


 loadData=()=>{

    var token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL+"/api/get-currency", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
      const  newData =data.data[0].rates
      const  addData =data.data[0].store_currency_codes
      this.setState({ currencyList: newData });
      this.setState({ addedCurrency: addData });
      this.setState({ showModal: true });
      
      
      });

  }
   addCurrency=(id)=>{

    var currency_id= id;
    var token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL+"/api/add-currency-for-store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ currency_code_id:currency_id})
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('add_button_'+id).textContent ='Added';
        document.getElementById('add_button_'+id).disabled =true;
        document.getElementById('add_button_'+id).className ='bg-[#00acc1] text-white font-semibold  py-2 px-4 border border-[#00acc1]';
        console.log(data);
        this.changeStatus(1); 
       
      
      });
  }
  onCloseModel=()=>{

    this.setState({ showModal: false });
   
   
 }
 render() {
  var currency_td_data = this.state.currencyList.map((item) => {
    var currency_found = false;

    this.state.addedCurrency.map((item2) => {
      if(item2.currency_code == item.currency_code && item2.service_id ==item.service_id ){
        currency_found =true;

      }
    })
    return (
      <tr
        key={item.id}
        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
      >
        <td className="px-6 py-4">{item.currency_code}</td>
        <td className="px-6 py-4">{item.rate}</td>

        <td className="px-6 py-4">{item.service_data.service_name}</td>

        <td className="px-6 py-4">
          {currency_found ?(
          <button className="bg-[#00acc1] text-white font-semibold  py-2 px-4 border border-[#00acc1] " id={"add_button_"+item.id}  disabled>
            Added
          </button>
          ):<button className="bg-transparent hover:bg-[#00acc1] text-[#00acc1] font-semibold hover:text-white py-2 px-4 border border-[#00acc1] hover:border-transparent rounded" id={"add_button_"+item.id} onClick={() => this.addCurrency(item.id)}>
          Add
        </button>}
        </td>
      </tr>
    );
  });


  return (
    <>
      <button
        className="bg-transparent hover:bg-[#00acc1] text-[#00acc1] font-semibold hover:text-white py-2 px-4 border border-[#00acc1]  rounded"
        type="button"
        onClick={() => this.loadData()}
      >
       Add Currency
      </button>
      {this.state.showModal ? (
        <>
          <div className=" overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font=semibold">All Currencies</h3>
                
                </div>
                <div className="relative overflow-x-auto overflow-y-scroll shadow-md sm:rounded-lg" style={this.divStyle}>

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 h-full" >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Currency
              </th>
              <th scope="col" className="px-6 py-3">
                Rate
              </th>
              <th scope="col" className="px-6 py-3">
                Service
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{currency_td_data}</tbody>
        </table>
       
        
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-[#00acc1] background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => this.onCloseModel()}
                  >
                    Close
                  </button>
               
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
      }
};

export default NewModal;
