import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import AllCurrencies from "./AllCurrencies";
import TableData from "./TableData";
class DashboardData extends React.Component {



  render() {

 
       return <SideBar />;
    
 
  }
}
export default DashboardData;
