import React, { useEffect, useState } from "react";
class AllCurrencies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }
  componentDidMount() {
    this.callApi = this.callApi.bind(this);
    this.callApi();
  }
  callApi() {
    var token = localStorage.getItem("token");
    var url = process.env.REACT_APP_API_URL;
    fetch(process.env.REACT_APP_API_UR+"/api/get-currency", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data[0].rates);
        this.setState({ list: data.data[0].rates });
      });
  }
  addCurrency(id){
    console.log(id);
    var currency_id= id;
    var url = process.env.REACT_APP_API_URL;
    var token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_UR+"/api/add-currency-for-store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ currency_code_id:currency_id})
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
       
      });
  }
  render() {
    let tb_data = this.state.list.map((item) => {
      return (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
        >
          <td className="px-6 py-4">{item.currency_code}</td>
          <td className="px-6 py-4">{item.rate}</td>

          <td className="px-6 py-4">{item.service_data.service_name}</td>

          <td className="px-6 py-4">
            <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" onClick={() => this.addCurrency(item.id)}>
              Add
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Currency
              </th>
              <th scope="col" className="px-6 py-3">
                Rate
              </th>
              <th scope="col" className="px-6 py-3">
                Service
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{tb_data}</tbody>
        </table>
      </div>
    );
  }
}
export default AllCurrencies;
