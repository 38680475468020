import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
 

class TableData extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      list: [],
      update_status:0,
      service_list: [],
      divStyle:{
        maxWidth:'1200px',
    
      },
      currentInstance: this

  
    };
    this.callApi = this.callApi.bind(this);
    this.changeStatus = props.changeStatus.bind(this);
  
  }

  componentDidMount() {
    this.callApi = this.callApi.bind(this);
    this.callApi();


  }
 
  deleteRowData=(id)=>{
    confirmAlert({
      title: 'Delete Currency',
      message: 'Do you want to delete this currency from store?.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteData(id)
        },
        {
          label: 'No',
        
        }
      ]
    });
    console.log(id);

  }
   deleteData = (id) => {
    console.log(id);
    var token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL+"/api/delete-currency-for-store/" + id, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.success);
        if(data.success){
          this.callApi();
  
        }
      
      });
  
  }
  updateData(id){
    console.log(id);
    var manual_value= document.getElementById("manual_value_"+id).value;
    var error_margin= document.getElementById("error_margin_"+id).value;
    var service_id= document.getElementById("service_id_"+id).value;
    var currency_id= id;

    var token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL+"/api/update-currency-for-store", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ manual_value:manual_value,error_margin:error_margin,service_id:service_id,currency_id:currency_id})
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.success);
        if(data.success){
          this.callApi();
  
        }
       
      });
  }
  callApi() {
    var token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL+"/api/get-currency-for-store", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data[0].rates);
        this.setState({ list: data.data[0].rates });
        this.setState({ service_list: data.data[0].services });
      });
  }
  static getDerivedStateFromProps(props, state) {
    if(props.data == 1){
    state.currentInstance.callApi();
    state.currentInstance.changeStatus(0); 
    }
    return true;

  }
  
  
  render() {
     console.log('state2--'+this.state.update_status)
    let services = this.state.service_list.map((item2) => {
      return (
        <option key={item2.id} value={item2.id}>
          {item2.service_name}
        </option>
      );
    });
    let tb_data = this.state.list.map((item) => {
      return (
        <tr
          key={item.id}
          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 "
        >
          <td className="px-6 py-4 text-base">{item.currency_code}</td>
          <td className="px-6 py-4">
            {" "}
            <select
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-base"
              id={"service_id_"+item.id}
              defaultValue={item.service_id}
              onChange={() => this.updateData(item.id)}
              
            >
              {services}
            </select>
          </td>
          <td className="px-6 py-4 text-base">{item.rate}</td>
          <td className="px-6 py-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-base"
              type="number"
              min={0}
              id={"error_margin_"+item.id}
              name="error_margin"
              defaultValue={item.error_margin}
              onBlur={() => this.updateData(item.id)}
    
            />
          </td>
          <td className="px-6 py-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-base"
              type="number"
              min={0}
              id={"manual_value_"+item.id}
              name="manual_value"
              defaultValue={item.manual_updated_value}
              onBlur={() => this.updateData(item.id)}
            />
          </td>
          <td className="px-6 py-4 text-base">{item.display_rate}</td>
          <td className="px-6 py-4 text-base">
            <button

              className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded text-base"
              onClick={() => this.deleteRowData(item.id)}
            >
              Remove
            </button>

        
          </td>
        </tr>
      );
    });

    return (
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg flex flex-col m-20">
         
        <table className="text-sm text-left text-gray-500 dark:text-gray-400 h-full" >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 text-lg">
                Currency
              </th>
              <th scope="col" className="px-6 py-3 text-lg">
                Service
              </th>
              <th scope="col" className="px-6 py-3 text-lg">
                Original Rate
              </th>
              <th scope="col" className="px-6 py-3 text-lg">
                Error Margin(%)
              </th>
              <th scope="col" className="px-6 py-3 text-lg">
                Manual Update Value
              </th>
              <th scope="col" className="px-6 py-3 text-lg">
                Display Rate
              </th>
              <th scope="col" className="px-6 py-3 text-lg">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{tb_data}</tbody>
        </table>
      </div>
    );
  }
}
export default TableData;
