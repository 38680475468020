import React,{ useEffect,useState } from 'react';
import TableData from '../../components/TableData';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import DasboardData from '../../components/DashboardData';

export default function Dashboard() {

  
    
  


   
    return (
        <div>
            <DasboardData/>
     
     
        </div>
    );
}