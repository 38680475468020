import React, { useEffect, useState } from "react";
class AlertMessage extends React.Component {
  constructor(props){
    super(props);
    this.messageHeader=props.messageHeader;
    this.message=props.message;
  }
  render() {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">{this.messageHeader} </strong>
        <span className="block sm:inline">{this.message}</span>
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
         
        </span>
      </div>
    );
  }
}
export default AlertMessage;
