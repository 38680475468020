import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';


function App() {
  var token = localStorage.getItem("token");
    if(!token) {
    return <Login />
  }
  return (
    <div className="">
    <div className="">
     <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="/dashboard" element={<Dashboard/>} />
            
        </Routes>
      </BrowserRouter>
    </div>
  </div>
  );

}

export default App;