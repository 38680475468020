import React,{ useState } from 'react';
import AlertMessage from '../../components/AlertMessage';

export default function Login() {
    
    async function loginUser(credentials) {
   
        return fetch(process.env.REACT_APP_API_URL+'/api/user-login', {
           
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        })
          .then(data => data.json())
       }
    
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loginError, setloginError] = useState(false);
    const [errorMessageHeader, seterrorMessageHeader] = useState('');
    const [errorMessage, seterrorMessage] = useState('');
  
    const handleSubmit = async e => {
      e.preventDefault();
  
      const response = await loginUser({
        email,
        password
      });
      if ('data' in response) {
        
          localStorage.setItem('token', response['data']['token']);
          localStorage.setItem('user', JSON.stringify(response['data']['name']));
          window.location.href = "/dashboard";
       
      } else {
        setloginError(true);
        seterrorMessageHeader('Error!');
        seterrorMessage('Email or Password Incorrect');
      }
      console.log(response);

    }
    return (
        
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
           
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                {loginError ?(
            <AlertMessage messageHeader ={errorMessageHeader} message={errorMessage}/>
            ):null}
                <h1 className="text-3xl font-semibold text-center text-teal-500 underline">
                   Sign in
                </h1>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <label
                            
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            onChange={e => setUserName(e.target.value)}
                            className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                           
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            onChange={e => setPassword(e.target.value)}
                            className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <a
                        href="#"
                        className="text-xs text-blue-500 hover:underline"
                    >
                        Forget Password?
                    </a>
                    <div className="mt-6">
                        <button type='submit' className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-teal-500 rounded-md hover:bg-teal-400 focus:outline-none focus:bg-teal-400">
                            Login
                        </button>
                    </div>
                </form>

                
            </div>
        </div>
    );
}