import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import TableData from "./TableData";
import AllCurrencies from "./AllCurrencies";
import Modal from "./Modal";
import NewModal from "./NewModal";
class SideBar extends React.Component {
   constructor(props) {
   
      super(props);
      this.state = {
        currency_list:0
     
    
    
      };
      this.changeStatus = this.changeStatus.bind(this);
      
    }

    changeStatus(status){
      let data = status;
      console.log('status---'+this.state.currency_list)

        this.setState({ currency_list: data});

    }
   
    
  
   
  render() {
    console.log('state---'+this.state.currency_list)
    return (
     <div className="">
         
          <NavBar/>
          
          
       
         
         
            <div className="flex flex-col items-end m-3"> <NewModal filters={true} changeStatus={this.changeStatus}/></div>

    
            <TableData gradient={true} changeStatus={this.changeStatus} data={this.state.currency_list} />
  
          
       
   

</div>

    );
  }
}
export default SideBar;
